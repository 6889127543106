import React from 'react';

const UserProfile = ({ isAuth,ClassName}) => {
  return (
    <>
      {/* 条件渲染：当isAuth.photo存在时，渲染img标签显示用户头像 */}
      {isAuth.photo && <img src={'../portrait/' + isAuth.photo + '.png'} alt="User Avatar" className={ClassName} />}
    </>
  );
};

export default UserProfile;
