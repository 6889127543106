import React, { useState, useEffect } from 'react';

const BackgroundImageSlider = ({ images, duration }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // 开始淡出当前图片，准备淡入下一张图片

      setTimeout(() => {
        // 更新当前和下一张图片的索引
        setCurrentIndex(nextIndex);
        setNextIndex((nextIndex + 1) % images.length);
        setFade(true); // 重置为淡入状态
      }, duration * 0.1); // 在总持续时间的10%后执行
    }, duration);

    return () => clearInterval(interval);
  }, [nextIndex, images.length, duration]);

  return (
    <div style={{ width: '100%', height: '100vh', position: 'relative', overflow: 'hidden' }}>
      {images.map((image, index) => (
        <div
          key={index}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            transition: 'opacity 1s ease-in-out',
            opacity: index === currentIndex ? (fade ? 1 : 0) : index === nextIndex ? (fade ? 0 : 1) : 0
          }}
        />
      ))}
    </div>
  );
};

export default BackgroundImageSlider;