import React, { Component } from 'react'
import './index.css'
import axios from 'axios';
import { Button, notification } from 'antd';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            num: 0,
            stateLock: false,
            indexList: [],//当前渲染的页面数据
            current: 1, //当前页码
            pageSize: 6, //每页显示的条数
            totalPage: 0,//总页数
        })
    }

    UNSAFE_componentWillMount() {
        let isAuth = JSON.parse(localStorage.getItem("phone"))
        console.log(isAuth.id);
        const aa = []
        axios.get('https://www.yjbplay.top/api1/information/reply?id= ' + isAuth.id).then(
            response => {
                // console.log(response.data);
                const res = response.data
                let reply_arr = new Array(res.length).fill('').map((item, index) => index);
                reply_arr.map((item) => {
                    aa.push(res[item])
                })
                console.log(res);
                const numm = reply_arr.slice(this.state.num, this.state.num + this.state.pageSize)
                console.log(numm);
                this.setState({
                    reply_arr,
                    reply: aa,
                    stateLock: true,
                    totalPage: Math.ceil(aa.length / this.state.pageSize), //设置总页数
                    indexList: numm,
                })
                console.log(numm);
            })
    }

    localPage = () => {
        const current = this.state.current
        if (this.state.num > 0) {
            if (this.state.num > this.state.reply_arr) {
                var num = this.state.num - this.state.pageSize * 2
            } else {
                var num = this.state.num - this.state.pageSize
            }
            console.log(num)
            console.log(this.state.num)
            const numm = this.state.reply_arr.slice(num - this.state.pageSize, num)
            console.log(numm)
            if (current > 1) {
                this.setState({
                    current: current - 1,
                    num,
                    indexList: numm
                })
            }
        }


    }
    nextPage = () => {
        const current = this.state.current
        const totalPage = this.state.totalPage
        if (this.state.num < this.state.reply_arr.length) {
            if (this.state.num == 0) {
                var num_o = this.state.pageSize + this.state.num
                var num = this.state.num + this.state.pageSize + num_o
            } else {
                var num_o = 0
                var num = this.state.num + this.state.pageSize
            }
            const numm = this.state.reply_arr.slice(this.state.num + num_o, num_o + this.state.num + this.state.pageSize)
            if (current < totalPage) {
                this.setState({
                    current: current + 1,
                    num,
                    indexList: numm
                })
            }
        }
    }


    handleIsSee = (e) => {
        // console.log(e.target.parentNode.id)
        const code = e.target.parentNode.id
        const see = e.target.parentNode.parentNode.id
        let count = localStorage.getItem("count")
        console.log(see)
        console.log(count)
        if (see == "false") {
            count = count - 1
            localStorage.setItem("count", count)
        }
        axios.get('https://www.yjbplay.top/api1/information/reply?code= ' + code).then(
            response => {
            })
    }
    render() {
        let isAuth = JSON.parse(localStorage.getItem("phone"))

        return (
            <div>

                {
                    this.state.stateLock ? (this.state.indexList.map((i) => {

                        return (
                            // 
                            <div className="reply_log" key={i} id={this.state.reply[i].see}>
                                <a href={('/message?PostsId=' + this.state.reply[i].id)} onClick={(e) => this.handleIsSee(e)} id={this.state.reply[i].code}>
                                    <p>{this.state.reply[i].username}</p>
                                    {
                                        isAuth != '' ? (<p>回复：{isAuth.name}:</p>) : []
                                    }
                                    <p>{this.state.reply[i].mssage}</p>
                                    {
                                        this.state.reply[i].see == "false" ? (<div className="nosee"></div>) : []
                                    }

                                </a>
                                <div className="setpage_button_box">
                    <div className="setpage_button">
                        <Button className="local_page" onClick={this.localPage}>上一页</Button>
                        <p>{this.state.current}/{this.state.totalPage}</p>
                        <Button className="next_page" onClick={this.nextPage}>下一页</Button>
                    </div>
                </div>
                            </div>)

                    })) : [<div>正在加载中</div>]
                }

            </div>
        )
    }
}
